<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editSurvey') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          input-element-chooser
          on-click-action-v1
        sidebar-tab-predefined-style(property="selectedElement" component="survey")
          custom-theme-style
          om-size(
            label="optionWidth"
            propsProperty="selectedElement"
            :subPath="`subElements.option.${getDevice}`"
            :inputType="type"
            typeOfComponent="survey"
          )
            template(#after)
              om-range-input.mt-3(
                v-if="options.length > 1"
                label="spaceBetweenOptions"
                property="spaceBetweenOptions"
                :editMobile="true"
                typeOfComponent="survey"
                :step="1"
              )
              om-switch#breakLines(
                v-if="breakLines"
                :label="$t('breakLines')"
                v-model="orientation"
                typeOfComponent="survey"
              )
          sidebar-accordion(:title="$t('fontEdit')" :addDeviceSelector="true")
            om-font(
              propsProperty="selectedElement"
              :subPath="`subElements.option.${getDevice}`"
              typeOfComponent="survey"
              :editMobile="true"
            )
          sidebar-accordion(:title="$t('background')" :addDeviceSelector="true")
            om-color-input(
              label="fill"
              :property="`subElements.option.${getDevice}.background.color`"
              typeOfComponent="survey"
              :onlySolid="true"
            )
          om-border-box(
            propsProperty="selectedElement"
            :subPath="`subElements.option.${getDevice}`"
            typeOfComponent="survey"
            :editMobile="true"
          )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import { mapState } from 'vuex';
  import { get as _get } from 'lodash-es';
  import inputsPaneMixin from '@/editor/mixins/inputsPane';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      OnClickActionV1: () => import('./OnClickActionV1.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },

    mixins: [inputsPaneMixin, itemMixin],

    computed: {
      ...mapState(['mobilePreview']),
      orientation: {
        get() {
          return this.smartGetValueOf(`${this.getDevice}.orientation`);
        },
        set(orientation) {
          this.smartSetValueOf(`${this.getDevice}.orientation`, orientation);
        },
      },
      getDevice() {
        return this.mobilePreview ? 'mobile' : 'desktop';
      },
      breakLines() {
        return this.selectedElement.subElements.option[this.getDevice].smartSize.type === 'manual';
      },
      options() {
        return _get(this.selectedElement, 'data.form.customSettings.options');
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'survey',
        };
      },
    },
    watch: {
      'selectedElement.subElements.option': {
        handler(v) {
          if (v[this.getDevice].smartSize.type !== 'manual') {
            this.smartSetValueOf(`${this.getDevice}.orientation`, false);
          }
        },
        immediate: true,
        deep: true,
      },
    },
  };
</script>
<style lang="sass" scoped>
  .sidebar-tab-content
    ::v-deep .sidebar-title-tooltip-container
      flex: 1
    ::v-deep .epic-simple-input
      position: relative
      .select-arrow
        position: absolute
        top: 0.4rem !important
    ::v-deep .switch-label
      flex: 0 0 85%
      max-width: 100%
</style>
